import _ from 'lodash';

import { Schemas } from '@api-client/generated/types';
import {
  InvoiceZeroVATReason,
  VAT_DEFAULT_PERCENT,
  VAT_MIDDLE_PERCENT,
} from '@constants';

type InvoiceItem = Schemas.InvoiceItem;

const defaultPercent = VAT_DEFAULT_PERCENT / 100;
const middlePercent = VAT_MIDDLE_PERCENT / 100;

export const getTotalAmount = (items: InvoiceItem[]): number =>
  +_.sum(items.map((item) => item.totalPrice)).toFixed(2);

export const getSubTotalAmount = (items: InvoiceItem[]): number =>
  +_.sum(items.map((item) => item.unitPrice || 0 * item.quantity)).toFixed(2);

export const getItemsByDefaultTaxRate = (items: InvoiceItem[]) =>
  items.filter((item) => item.taxRate === defaultPercent);

export const getItemsByMiddleTaxRate = (items: InvoiceItem[]) =>
  items.filter((item) => item.taxRate === middlePercent);

export const getAmountByDefaultTaxRate = (items: InvoiceItem[]): number => {
  const itemsByDefaultTaxRate = getItemsByDefaultTaxRate(items);

  return +_.sum(
    itemsByDefaultTaxRate.map(
      (item) => item.unitPrice || 0 * item.quantity * item.taxRate,
    ),
  ).toFixed(2);
};

export const getAmountByMiddleTaxRate = (items: InvoiceItem[]): number => {
  const itemsByMiddleTaxRate = getItemsByMiddleTaxRate(items);

  return +_.sum(
    itemsByMiddleTaxRate.map(
      (item) => item.unitPrice || 0 * item.quantity * item.taxRate,
    ),
  ).toFixed(2);
};

export const hasIntraEU = (items: InvoiceItem[] = []): boolean =>
  items.some(
    (item) =>
      item.settings?.zeroVATReason ===
      InvoiceZeroVATReason.IntraCommunityReverseCharge,
  );
