//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//

import {
  Params$UserController_updateConfig,
  ResponseContentType$UserController_updateConfig,
  Response$UserController_updateConfig$Status$200,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';
export const useUserController_updateConfig = () =>
  useApiClientMutation<
    Params$UserController_updateConfig,
    Response$UserController_updateConfig$Status$200[ResponseContentType$UserController_updateConfig]
  >({ method: 'UserController_updateConfig' });
