import { Tooltip } from 'antd';
import pdfMake from 'pdfmake/build/pdfmake';
import { useEffect, useMemo, useState } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';

import { Button } from '@ui-kit/Button/Button';
import { colors } from '@theme';
import { useAccount } from '@hooks';
import { useDocumentController_updateOneById } from '@api-client/generated/DocumentController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { useUserController_updateConfig } from '@api-client/generated/UserController/updateConfig';

import { useInvoiceGenerator } from '../../hooks';
import DraftModal from '../../modals/DraftModal';
import FinalizeModal from '../../modals/FinalizeModal';
import FormDraftFinalizeActionsFirstTooltip from './FormDraftFinalizeActionsFirstTooltip';
import { checkingRequiredFields, getDocumentDefinition } from './helpers';
import * as S from './styled';

type UpdateDocumentDto = Schemas.UpdateDocumentDto;

type FormDraftFinalizeActionsProps = {
  document: Schemas.Document;
  company: Schemas.Company;
};

pdfMake.fonts = {
  Roboto: {
    normal:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
};

pdfMake.tableLayouts = {
  lightHorizontalLines: {
    vLineWidth: () => 0,
    hLineWidth: (rowIndex) => (rowIndex === 0 ? 0 : 1),
    hLineColor: () => colors.darkgrey,
    paddingLeft: (rowIndex) => (rowIndex === 0 ? 0 : 8),
    paddingRight: (rowIndex, node) =>
      rowIndex === (node.table?.widths?.length ?? 0) - 1 ? 0 : 8,
    paddingTop: () => 4,
    paddingBottom: () => 4,
  },
};

const FormDraftFinalizeActions = ({
  company,
  document,
}: FormDraftFinalizeActionsProps) => {
  const { companyId, account, setAccountConfig } = useAccount();
  const navigate = useNavigate();

  const { isOnboardingCompleted, currentStep } = useInvoiceGenerator();
  const isStepFinalize = currentStep === 'FINALIZE';

  const [isVisibleDraftModal, setIsVisibleDraftModal] = useState(false);
  const [isVisibleFinishModal, setIsVisibleFinishModal] = useState(false);
  const [isVisibleFinalizeTooltip, setIsVisibleFinalizeTooltip] =
    useState(false);
  const [isLoadingDraft, setIsLoadingDraft] = useState(false);

  const hasDisabledFinish = useMemo(
    () => checkingRequiredFields(document, company),
    [document, company],
  );

  const blocker = useBlocker(
    ({ currentLocation, nextLocation, historyAction }) => {
      const isBlocked =
        hasDisabledFinish &&
        currentLocation.pathname !== nextLocation.pathname &&
        nextLocation.pathname !== `/invoices/${document.id}` &&
        historyAction !== 'REPLACE';

      if (isBlocked) {
        setIsVisibleDraftModal(true);
        return true;
      } else {
        return false;
      }
    },
  );

  const { mutateAsync: updateDocument, isPending: isLoadingUpdateDocument } =
    useDocumentController_updateOneById();

  const { mutateAsync: updateUserConfig } = useUserController_updateConfig();

  useEffect(() => {
    if (isStepFinalize) {
      setIsVisibleFinalizeTooltip(true);
    }
  }, [isStepFinalize]);

  const handleUpdateFile = async (blob: Blob) => {
    if (!companyId || !document) return;

    const formData = new FormData();
    const documentName = document.name || t('invoiceGenerator.document.name')();

    formData.append('file', blob, `${documentName}.pdf`);
    formData.append('fileName', `${documentName}.pdf`);
    formData.append('name', documentName);

    await updateDocument({
      parameter: { companyId: companyId, id: document.id },
      requestBody: formData as UpdateDocumentDto,
    });
  };

  const handleDraftDocument = async (blob: Blob, withRedirect = true) => {
    if (!companyId || !document) return;

    setIsLoadingDraft(true);

    await handleUpdateFile(blob);
    await updateDocument({
      parameter: { companyId: companyId, id: document.id },
      requestBody: {} as UpdateDocumentDto,
    });

    setIsLoadingDraft(false);
    setIsVisibleDraftModal(false);

    withRedirect && navigate(`/invoices/${document.id}`);
  };

  const handleFinalizeDocument = async (blob: Blob) => {
    if (!companyId || !document) return;

    const onboardingCompleted = {
      onboardingCompleted: {
        invoiceConstructor: true,
      },
    };

    await handleUpdateFile(blob);
    await updateDocument({
      parameter: { companyId: companyId, id: document.id },
      requestBody: {
        ...document,
        documentMetadata: {
          ...document.documentMetadata,
          isReviewed: true,
          isFinalized: true,
        },
      } as UpdateDocumentDto,
    });

    if (!isOnboardingCompleted) {
      await updateUserConfig({ requestBody: onboardingCompleted });
    }

    setIsVisibleFinishModal(false);
    setAccountConfig(onboardingCompleted);

    navigate('/invoices');
  };

  const handleGeneratePdf = (isDraft = false, withRedirect = true) => {
    const documentDefinition = getDocumentDefinition({
      locale: account?.locale,
      document,
      company,
    });

    pdfMake
      .createPdf(documentDefinition)
      .getBlob((blob) =>
        isDraft
          ? handleDraftDocument(blob, withRedirect)
          : handleFinalizeDocument(blob),
      );
  };

  const handleConfirmDraft = () => {
    handleGeneratePdf(true, false);
    setIsVisibleDraftModal(false);
    blocker.state === 'blocked' && blocker.proceed();
  };

  const handleCancelDraft = () => {
    setIsVisibleDraftModal(false);
    blocker.state === 'blocked' && blocker.reset();
  };

  return (
    <>
      <DraftModal
        open={isVisibleDraftModal}
        isLoading={isLoadingUpdateDocument}
        onConfirm={handleConfirmDraft}
        onCancel={handleCancelDraft}
      />

      <FinalizeModal
        open={isVisibleFinishModal}
        isLoading={isLoadingUpdateDocument}
        onConfirm={() => handleGeneratePdf()}
        onCancel={() => setIsVisibleFinishModal(false)}
      />

      <S.ButtonsGroup align="center" gap={8}>
        <Button
          type="primary"
          onClick={() => handleGeneratePdf(true)}
          loading={isLoadingDraft}
          block
        >
          {t('invoiceGenerator.draft.buttonSaveDraft')()}
        </Button>

        {isOnboardingCompleted || !isStepFinalize ? (
          <Tooltip
            title={
              hasDisabledFinish &&
              t('invoiceGenerator.document.helpTextFinalize')()
            }
            placement="bottom"
          >
            <Button
              type="primary"
              onClick={() => setIsVisibleFinishModal(true)}
              disabled={hasDisabledFinish}
              block
            >
              {t('invoiceGenerator.draft.buttonFinalize')()}
            </Button>
          </Tooltip>
        ) : (
          <FormDraftFinalizeActionsFirstTooltip
            open={isVisibleFinalizeTooltip}
            onClick={() => setIsVisibleFinalizeTooltip(false)}
          >
            <Button
              type="primary"
              onClick={() => {
                setIsVisibleFinishModal(true);
                setIsVisibleFinalizeTooltip(false);
              }}
              disabled={hasDisabledFinish}
              block
            >
              {t('invoiceGenerator.draft.buttonFinalize')()}
            </Button>
          </FormDraftFinalizeActionsFirstTooltip>
        )}
      </S.ButtonsGroup>
    </>
  );
};

export default FormDraftFinalizeActions;
