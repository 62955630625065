import { ConfigProvider, Flex, Form, Space, Typography } from 'antd';
import { Dayjs } from 'dayjs';
import i18next from 'i18next';
import { ChangeEvent, ReactNode, useEffect, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { IconChevronDown } from '@ui-kit/Icon';
import { IconStatusWarning } from '@assets';
import Amount from '@entities/common/Amount';
import RangePicker from '@entities/common/RangePicker';
import SearchControl from '@entities/common/SearchControl';
import {
  FilterConstructorDrawer,
  FilterControl,
} from '@entities/filters/FilterConstructor';
import FilterFormLayout from '@entities/filters/FilterConstructor/FilterFormLayout';
import { GTMEventName, sendGTMEvent } from '@utils';
import { Currency, DEFAULT_TIMEOUT_FOR_SEARCH } from '@constants';
import { ApiTypes } from '@api';

import {
  FilterForm,
  filterFormFromQuery,
  filterFormToQuery,
  type QueryParams,
  queryParamsSchema,
} from './schema';
import * as S from './styled';

type ModuleFilterKeys = Pick<
  QueryParams,
  | 'accountIds'
  | 'categoryIds'
  | 'contactIds'
  | 'onlyWithoutDocuments'
  | 'showByPlDate'
>;

type Props<T = QueryParams> = {
  /** Disabling/enabling specific form fields */
  hiddenFormFields?: Set<
    | 'categoryIds'
    | 'contactIds'
    | 'accountIds'
    | 'projectIds'
    | 'onlyWithoutDocuments'
    | 'showByPlDate'
    | 'date'
  >;
  /** Invert theme color for controls */
  isDarkTheme?: boolean;
  /** Default form values/query params  */
  queryParams?: Partial<T>;
  /** Slot for custom action buttons */
  right?: ReactNode | ReactNode[];
  /** Table columns select, always last on the right */
  tableConfigurator?: ReactNode;
  /** Total amount of transactions to display hint */
  transactionsTotals?: ApiTypes.TransactionControllerGetTotalsResponse;
  /** Loading state for transactions */
  isLoading: boolean;
  /** Loading state for transactions */
  isFetching: boolean;
  /** Callback to receive form values */
  onFormUpdate?: (values: Partial<QueryParams>) => void;
  /** Display additional filters by income/expense/accounts */
  showSecondRow?: boolean;
};

const moduleFilterSchema = queryParamsSchema.pick({
  categoryIds: true,
  contactIds: true,
  accountIds: true,
  projectIds: true,
  onlyWithoutDocuments: true,
  showByPlDate: true,
});

function TransactionsTableFilterForm(props: Props) {
  const [form] = Form.useForm<FilterForm>();

  const isCurrencySelectionAvailable = useMemo(
    () => (props.transactionsTotals?.balanceByCurrencies || []).length > 1,
    [props.transactionsTotals],
  );

  const isCashFlowSelectionAvailable = useMemo(() => {
    if (!props.queryParams?.currency) {
      return !isCurrencySelectionAvailable;
    } else {
      return !!props.transactionsTotals;
    }
  }, [props, isCurrencySelectionAvailable]);

  const selectedCurrencyAccount = useMemo(() => {
    if (props.queryParams?.currency) {
      return (
        props.transactionsTotals?.balanceByCurrencies.find(
          (e) => e.currencyCode === props.queryParams?.currency,
        ) || null
      );
    } else if (props.transactionsTotals?.balanceByCurrencies.length === 1) {
      return props.transactionsTotals?.balanceByCurrencies[0];
    }

    return null;
  }, [props.queryParams?.currency, props.transactionsTotals]);

  const moduleFilterControls = useMemo(
    () =>
      [
        {
          label: t('filter.category.label')(),
          type: 'list-categories',
          formName: 'categoryIds',
        },
        {
          label: t('filter.contacts.label')(),
          type: 'list-contacts',
          formName: 'contactIds',
          hidden: props.hiddenFormFields?.has('contactIds'),
        },
        {
          label: t('filter.bank.label')(),
          type: 'list-banks',
          formName: 'accountIds',
        },
        {
          label: t('filter.project.label')(),
          type: 'list-projects',
          formName: 'projectIds',
          hidden: props.hiddenFormFields?.has('projectIds'),
        },
        {
          label: t('transactionsPage.label.withoutDocuments')(),
          type: 'switch',
          formName: 'onlyWithoutDocuments',
        },
        {
          label: t('transactionsPage.label.byPlDate')(),
          type: 'switch',
          formName: 'showByPlDate',
          isBoolean: true,
        },
      ] as const satisfies FilterControl[],
    [props.hiddenFormFields],
  );

  useEffect(() => {
    if (props.queryParams) {
      const formValues = filterFormFromQuery.parse(props.queryParams);
      // @ts-expect-error i will fix it later
      form.setFieldsValue(formValues);
    }
  }, [props.queryParams]); // eslint-disable-line

  useEffect(() => {
    if (props.transactionsTotals?.balanceByCurrencies.length === 1) {
      form.setFieldsValue({
        currency: props.transactionsTotals.balanceByCurrencies[0].currencyCode,
      });
    }
  });

  function onFormChange() {
    const values = form.getFieldsValue();

    if (props.onFormUpdate) {
      props.onFormUpdate(filterFormToQuery.parse(values));
    }

    sendGTMEvent(GTMEventName.AppliedTransactionFilters);
  }

  function handleModuleFilterChange(values: ModuleFilterKeys | null) {
    const schemaValues = moduleFilterSchema.parse(values);
    if (schemaValues) {
      form.setFieldsValue({ ...schemaValues });
    }
    onFormChange();
  }

  const handleInputChange = useDebouncedCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue('term', event.target.value);
      onFormChange();
    },
    DEFAULT_TIMEOUT_FOR_SEARCH,
  );

  function handleDatePickerChange(values: (Dayjs | null)[] | null) {
    form.setFieldValue('date', values || null);
    onFormChange();
  }

  function handleCurrencyChange(value: Currency) {
    form.setFieldValue('currency', value);
    // for now we can't calculate total amount for different currencies
    form.setFieldsValue({ flowType: null });
    onFormChange();
  }

  function handleCashFlowChange(value: 'money_in' | 'money_out') {
    if (props.queryParams?.flowType === value) {
      form.setFieldValue('flowType', null);
    } else {
      form.setFieldValue('flowType', value);
    }
    onFormChange();
  }

  function handleFilelessFilterChange() {
    form.setFieldValue(
      'onlyWithoutDocuments',
      !props.queryParams?.onlyWithoutDocuments,
    );
    onFormChange();
  }

  function getFormValuesForModuleFilter() {
    return moduleFilterSchema.parse(form.getFieldsValue());
  }

  return (
    <Form
      form={form}
      layout="horizontal"
      requiredMark={false}
      onFieldsChange={onFormChange}
    >
      <Flex vertical gap={24}>
        <FilterFormLayout
          isDarkTheme={props.isDarkTheme}
          left={
            <>
              <S.FormControl name="term">
                <SearchControl onChange={handleInputChange} />
              </S.FormControl>

              <S.Divider />

              <FilterConstructorDrawer
                form={form}
                initialParams={getFormValuesForModuleFilter()}
                controls={moduleFilterControls}
                // @ts-expect-error FilterConstructor is too dynamic for strict schema
                onSubmit={handleModuleFilterChange}
              />

              <S.FormControl
                name="date"
                hidden={props.hiddenFormFields?.has('date')}
              >
                <RangePicker onChange={handleDatePickerChange} />
              </S.FormControl>

              <Typography.Text>
                {i18next.format(
                  t(
                    'transactionsPage.transactionPluralForms.transaction_many',
                  )(),
                  'capitalize',
                )}
                :
                <S.TransactionsTotalAmount>
                  {props.transactionsTotals?.totalTransactions || '-'}
                </S.TransactionsTotalAmount>
              </Typography.Text>
            </>
          }
          right={
            props.right || props.tableConfigurator ? (
              <>
                {props.right}
                {props.tableConfigurator}
              </>
            ) : null
          }
        />

        <FilterFormLayout
          isDarkTheme={props.isDarkTheme}
          left={
            props.showSecondRow && (
              <>
                <S.FormControl name="currency">
                  <S.AccountSelect
                    size="large"
                    placeholder="All accounts balance"
                    suffixIcon={<IconChevronDown width={20} height={20} />}
                    allowClear={isCurrencySelectionAvailable}
                    disabled={!props.transactionsTotals}
                    options={props.transactionsTotals?.balanceByCurrencies.map(
                      (e) => ({
                        value: e.currencyCode,
                        label: `${e.currencyCode} balance`,
                      }),
                    )}
                    onChange={(option) =>
                      handleCurrencyChange(option as Currency)
                    }
                  />
                </S.FormControl>

                <S.TotalBalance>
                  <Space size={4}>
                    <span>Total:</span>
                    {selectedCurrencyAccount && props.transactionsTotals ? (
                      <Amount
                        currencyCode={selectedCurrencyAccount.currencyCode}
                        amount={props.transactionsTotals?.totalAmount}
                      />
                    ) : (
                      '-'
                    )}
                  </Space>
                </S.TotalBalance>

                <S.FormControl name="flowType">
                  <Flex gap={8}>
                    <ConfigProvider wave={{ disabled: true }}>
                      <S.CashFlowFilterButton
                        size="small"
                        variant="filled"
                        color="default"
                        isActive={props.queryParams?.flowType === 'money_in'}
                        disabled={!isCashFlowSelectionAvailable}
                        onClick={() => handleCashFlowChange('money_in')}
                      >
                        Money in:
                        {selectedCurrencyAccount ? (
                          <Amount
                            currencyCode={selectedCurrencyAccount.currencyCode}
                            amount={
                              props.transactionsTotals?.totalIncomingAmount || 0
                            }
                            withColors
                          />
                        ) : (
                          '-'
                        )}
                      </S.CashFlowFilterButton>

                      <S.CashFlowFilterButton
                        size="small"
                        variant="filled"
                        color="default"
                        isActive={props.queryParams?.flowType === 'money_out'}
                        disabled={!isCashFlowSelectionAvailable}
                        onClick={() => handleCashFlowChange('money_out')}
                      >
                        Money out:
                        {selectedCurrencyAccount ? (
                          <Amount
                            currencyCode={selectedCurrencyAccount.currencyCode}
                            amount={
                              props.transactionsTotals?.totalOutgoingAmount || 0
                            }
                            withColors
                          />
                        ) : (
                          '-'
                        )}
                      </S.CashFlowFilterButton>
                    </ConfigProvider>
                  </Flex>
                </S.FormControl>
              </>
            )
          }
          right={
            props.showSecondRow &&
            !props.isLoading &&
            (props.transactionsTotals?.totalWithoutDocuments === 0 ? (
              <S.FilelessFilterButton
                size="small"
                variant="filled"
                color="default"
                disabled
              >
                <Space>
                  <Typography.Text type="success">
                    {t('transactionsPage.goodJob')()}
                  </Typography.Text>
                  {t('transactionsPage.allTheDocumentsAttached')()}
                </Space>
              </S.FilelessFilterButton>
            ) : (
              <S.FilelessFilterButton
                size="small"
                variant="filled"
                color="default"
                icon={<IconStatusWarning />}
                onClick={handleFilelessFilterChange}
                isActive={props.queryParams?.onlyWithoutDocuments}
              >
                <Space size={4}>
                  {props.transactionsTotals?.totalWithoutDocuments}
                  <Typography.Text type="danger">
                    {t(
                      /* @ts-expect-error plural rules works a bit differently */
                      'transactionsPage.transactionPluralForms.transaction',
                      {
                        count: props.transactionsTotals?.totalWithoutDocuments,
                      },
                    )()}
                  </Typography.Text>
                  {t('transactionsPage.withoutDocuments')()}
                </Space>
              </S.FilelessFilterButton>
            ))
          }
        />
      </Flex>
    </Form>
  );
}

export default TransactionsTableFilterForm;
