import { useEffect, useState } from "react";

type UseStorageOptions = {
  storage: Storage;
  enabled: boolean;
};

const defaultOptions: UseStorageOptions = {
  storage: window.localStorage,
  enabled: true,
};

export function useStorage<T>(
  key: string,
  defaultValue: T,
  opts?: Partial<UseStorageOptions>,
) {
  const options = { ...defaultOptions, ...opts };

  const [value, setValue] = useState(() => {
    if (!options.enabled) return defaultValue;

    let currentValue: T;
    try {
      const fromStorage = options.storage.getItem(key);
      currentValue = fromStorage ? JSON.parse(fromStorage) : defaultValue;
    } catch (err) {
      console.warn(err);
      currentValue = defaultValue;
    }
    return currentValue;
  });

  useEffect(() => {
    if (options.enabled) {
      options.storage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]); // eslint-disable-line

  return [value, setValue] as const;
}
