import { Flex, ModalProps as BaseModalProps } from 'antd';
import { FC, ReactNode } from 'react';

import { Button, ButtonProps } from '@ui-kit/Button/Button';

import * as S from './modalStyles';

type ModalSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type Props = {
  isOpen: boolean;
  /**
   * Modal size.
   * xs: 497,
   * sm: 603,
   * md: 709,
   * lg: 921,
   * xl: 1026,
   */
  size: ModalSize;
  header?: ReactNode;
  description?: ReactNode;
  okButton?: string | ButtonProps;
  cancelButton?: string | ButtonProps;
  optionalButton?: string | ButtonProps;
  gap?: number;
  onOptional?: () => void;
} & BaseModalProps;

const sizes: Record<ModalSize, number> = {
  xs: 497,
  sm: 603,
  md: 709,
  lg: 921,
  xl: 1026,
};

export const Modal: FC<Props> = ({
  isOpen,
  header,
  size,
  description,
  children,
  cancelButton = null,
  okButton = null,
  optionalButton = null,
  gap,
  onOk,
  onCancel,
  onOptional,
  footer,
  ...rest
}) => (
  <S.Modal
    hasHeader={!!header}
    open={isOpen}
    footer={
      <Flex justify="space-between" align="center">
        <div>
          {optionalButton &&
            (typeof optionalButton === 'string' ? (
              <Button onClick={onOptional} type="link">
                {optionalButton}
              </Button>
            ) : (
              <Button onClick={onOptional} type="link" {...optionalButton} />
            ))}
        </div>
        <Flex gap={12}>
          {cancelButton &&
            (typeof cancelButton === 'string' ? (
              <Button onClick={onCancel}>{cancelButton}</Button>
            ) : (
              <Button onClick={onCancel} {...cancelButton} />
            ))}
          {okButton &&
            (typeof okButton === 'string' ? (
              <Button onClick={onOk} type="primary">
                {okButton}
              </Button>
            ) : (
              <Button onClick={onOk} type="primary" {...okButton} />
            ))}
        </Flex>
      </Flex>
    }
    width={sizes[size]}
    onCancel={
      onCancel ||
      ((typeof cancelButton !== 'string' &&
        cancelButton?.onClick) as BaseModalProps['onCancel'])
    }
    {...rest}
  >
    <Flex gap={gap === undefined ? (description ? 24 : 16) : gap} vertical>
      <Flex gap={8} vertical>
        <S.Header>{header}</S.Header>
        <S.Description>{description}</S.Description>
      </Flex>
      <div>{children}</div>
    </Flex>
  </S.Modal>
);
