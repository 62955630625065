import { Checkbox, Flex, type FlexProps, Typography } from 'antd';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mediaWidth } from '@ui-kit/theme/mediaWidth';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .ui-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
`;

export const Inner = styled.div`
  margin-bottom: 24px;
`;

export const Wrapper = styled(Flex)<FlexProps>`
  ${mediaWidth.tablet(css`
    flex-direction: column;
    gap: 24px !important;
  `)};
`;

export const FormInner = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 8px;
  padding: 14px 22px;

  .ui-form-item {
    margin-bottom: 8px;

    label {
      font-size: 12px;
    }

    .ui-checkbox-wrapper {
      font-weight: 500;
      font-size: 14px;
    }
  }
`;

export const Info = styled(Typography.Paragraph)`
  &.ui-typography {
    font-size: 16px;
    margin-top: 16px;
  }
`;

export const Form = styled.div`
  width: 100%;
  max-width: 420px;

  ${mediaWidth.tablet(css`
    max-width: 100%;
  `)};
`;

export const Actions = styled.div`
  ${mediaWidth.tablet(css`
    text-align: center;
  `)};
`;

export const CustomCheckbox = styled(Checkbox)`
  && .ui-checkbox {
    align-self: flex-start;
  }
`;
