import { createContext } from 'react';

export type StepNameUnit =
  | 'NUMBER'
  | 'ISSUED_ON'
  | 'DUE_DATE'
  | 'DELIVERY_DATE'
  | 'CONTACT_INFORMATION'
  | 'ITEMS'
  | 'BANK_DETAILS'
  | 'FINALIZE';

export type InvoiceGeneratorContextProps = {
  isOnboardingCompleted: boolean | null;
  currentStep: StepNameUnit;
  updateStep: (name: StepNameUnit | null) => void;
};

export const InvoiceGeneratorContext =
  createContext<InvoiceGeneratorContextProps>(
    {} as InvoiceGeneratorContextProps,
  );
