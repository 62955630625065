import { Dropdown } from 'antd';
import { memo, useCallback, useId } from 'react';

import { Button } from '@ui-kit/Button/Button';
import { IconMoreVertical } from '@assets';
import ProjectPopupList from '@entities/projects/ProjectPopupList/ProjectPopupList';
import { Schemas } from '@api-client/generated/types';
import { useModalManager } from '@context/ModalManager/useModalManager';

import * as S from '../TransactionsTableFilterForm/styled';

const stopPropagation = (event: React.MouseEvent) => event.stopPropagation();

const NoPropagation = ({ children }: { children: React.ReactNode }) => (
  <div onMouseDown={stopPropagation}>{children}</div>
);

type ProjectCellProps = {
  project: Schemas.Contact | null;
  onProjectAssign: (projectId: string) => void;
};

const ProjectsCell = memo((props: ProjectCellProps) => {
  const dropdown = useId();
  const projectSelector = useId();

  const modalManager = useModalManager();

  const isDropdownVisible = modalManager.isOpen(dropdown);
  const isProjectSelectorVisible = modalManager.isOpen(projectSelector);

  const items = {
    items: [
      {
        key: 'assignProject',
        label: t(
          props.project
            ? 'transactionsPage.projectActions.replaceProject'
            : 'transactionsPage.projectActions.assignProject'
        )(),
        onClick: () => modalManager.replace(projectSelector),
      },
    ],
  } satisfies Parameters<typeof Dropdown>[0]['menu'];

  const handleAssignProject: typeof props.onProjectAssign = useCallback(
    (projectId) => {
      props.onProjectAssign(projectId);
      modalManager.pop(projectSelector);
    },
    [props.onProjectAssign, projectSelector, modalManager] // eslint-disable-line
  );

  return (
    <S.Wrapper
      isDropdownOpen={isDropdownVisible}
      isSelectorOpen={isProjectSelectorVisible}
      hasInfo={!!props.project}
      onClick={stopPropagation}
    >
      <span>{props.project?.name || t('transactionsPage.noProject')()}</span>
      <Dropdown
        dropdownRender={(menu) => <NoPropagation>{menu}</NoPropagation>}
        menu={items}
        open={isDropdownVisible}
        onOpenChange={modalManager.createSetModalVisibility(dropdown)}
        trigger={['click']}
      >
        <S.HoverableButton onMouseDown={stopPropagation}>
          <Button type="text" size="xs" icon={<IconMoreVertical />} />
        </S.HoverableButton>
      </Dropdown>

      <ProjectPopupList
        onSelect={handleAssignProject}
        popoverProps={{
          open: isProjectSelectorVisible,
          onOpenChange: modalManager.createSetModalVisibility(projectSelector),
          placement: 'bottomLeft',
        }}
      >
        <S.Invisible />
      </ProjectPopupList>
    </S.Wrapper>
  );
});

export default ProjectsCell;
