import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const animation = keyframes`
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
`;

export const Root = styled.div`
  overflow: auto;
  position: relative;
  height: 100%;
  flex: 1 1 100%;
  border: 1px solid ${({ theme }) => theme.colors.grey200};
  border-radius: 10px;
`;

export const Table = styled.table`
  display: grid;
  max-width: 100%;
`;

export const Thead = styled.thead`
  display: grid;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
  user-select: none;
`;

export const TBody = styled.tbody`
  display: grid;
  position: relative;
`;

export const TRow = styled('tr', {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected?: boolean }>`
  cursor: pointer;
  display: flex;
  scroll-margin-top: 50px; // for correct scrollIntoView behavior
  scroll-margin-bottom: 50px; // for correct scrollIntoView behavior
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.grey100 : theme.colors.white};

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.grey200};
  }

  &:hover {
    background-color: #eee;
  }
`;

export const TextEllipsis = styled.div`
  padding: 0 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;

export const Th = styled.th`
  padding: 16px;
  height: 38px;
  position: relative;
  white-space: nowrap;

  &:hover .resize-handle {
    width: 5px;
    background-color: ${({ theme }) => theme.colors.grey300};
  }
`;

export const Td = styled.td`
  display: flex;
  align-items: center;
  justify-self: stretch;
`;

export const Skeleton = styled.div`
  width: 100%;
  height: 50%;
  margin: 0 16px;
  align-self: center;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 4px;

  animation: ${animation} 1.5s infinite alternate;
`;

export const Icon = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.grey500};
  opacity: ${({ active }) => (active === false ? 0.35 : 1)};
`;

export const ResizeHandle = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 50%;
  border-radius: 2px;
  cursor: col-resize;
  background-color: ${({ theme }) => theme.colors.grey200};
  transition: width 0.1s;
`;
