import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { z } from 'zod';

import { Api, ApiTypes } from '@api';

import { TransactionControllerFindAllResponse } from 'src/api/generated';

export const schema = z.object({
  status: z.optional(z.enum(['active', 'inactive', 'finished'])),
  term: z.optional(z.string().default('')),
  page: z.optional(z.number().default(1)),
  perPage: z.optional(z.number().default(10)),
});

export type Schema = z.infer<typeof schema>;

const queryKeys = {
  all: 'transactions',
  totals: (filter: Schema) => ['transactions', 'totals', filter],
  detail: (id: string) => ['transactions', 'detail', id],
} as const;

export const useTransactionById = (id: string) => {
  const queryClient = useQueryClient();

  return useQuery<ApiTypes.Transaction>({
    queryKey: queryKeys.detail(id),
    queryFn: ({ signal }) =>
      Api.transactionControllerFindOneById(id, { signal }),
    staleTime: 1000 * 60,
    enabled: !!id,
    placeholderData: () => {
      const result =
        queryClient.getQueriesData<TransactionControllerFindAllResponse>({
          predicate: (query) =>
            query.queryKey[0] === 'transactions' &&
            query.queryKey[1] === 'list' &&
            (query.state.data?.records.some((e) => e.id === id) || false),
        });

      if (result.length > 0) {
        const [_key, transactions] = result[0];
        const transaction = transactions?.records.find((t) => t.id === id);
        if (transaction) {
          // @ts-expect-error we don't have potentialDocuments in ListTransactionDto but it's fine because it's a placeholder
          transaction.potentialDocuments = [];
          return transaction as unknown as ApiTypes.Transaction;
        }
      }
    },
  });
};

/**
 * Same as usual MutationOptions, except mutationFn, onSettled and onMutate.
 *
 * In consumer code prefer onSuccess and onError callbacks
 */
type MutationOptions = Omit<
  UseMutationOptions<
    ApiTypes.Transaction,
    Error,
    Partial<ApiTypes.Transaction> & { id: string },
    void
  >,
  'mutationFn' | 'onSettled' | 'onMutate'
>;

export const useTransactionUpdate = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      // we can't send full transaction because backend won't update it if documentIds present
      let dto: ApiTypes.UpdateTransactionDto = data;
      if (data.documents) {
        const documentIds = data.documents.map((d) => d.id).join(',');
        dto = { ...data, documentIds };
      }
      return Api.transactionControllerUpdateOneById(data.id, dto);
    },
    onMutate: (newData) => {
      queryClient.setQueryData(
        queryKeys.detail(newData.id),
        (oldData: Partial<ApiTypes.Transaction>) => ({
          ...oldData,
          ...newData,
        }),
      );
    },
    onSettled: (response) => {
      if (response) {
        queryClient.setQueryData(queryKeys.detail(response.id), () => response);

        // searching for all queries that contain changed transaction
        const result =
          queryClient.getQueriesData<TransactionControllerFindAllResponse>({
            predicate: (query) => {
              if (query.queryKey[0] !== 'transactions') return false;

              const isListQuery =
                query.queryKey[1] === 'list' &&
                (query.state.data?.records.some((e) => e.id === response.id) ||
                  false);
              const isTotalsQuery = query.queryKey[1] === 'totals';

              return isListQuery || isTotalsQuery;
            },
          });

        // if found - invalidate them all
        if (result.length > 0) {
          result.forEach(([key, _data]) => {
            queryClient.invalidateQueries({ queryKey: key });
          });
        }
      }
    },
    ...options,
  });
};
