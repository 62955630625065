import { type ModalProps } from 'antd';

import { Modal } from '@ui-kit/Modal/Modal';

type DraftModalProps = {
  isLoading: boolean;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
} & ModalProps;

const DraftModal = ({
  open,
  isLoading,
  onConfirm,
  onCancel,
}: DraftModalProps) => (
  <Modal
    isOpen={!!open}
    header={t('invoiceGenerator.draftConfirm.title')()}
    description={t('invoiceGenerator.draftConfirm.description')()}
    size="md"
    okButton={{
      loading: isLoading,
      children: t('invoiceGenerator.draftConfirm.buttonConfirm')(),
      onClick: onConfirm,
    }}
    cancelButton={{
      children: t('invoiceGenerator.draftConfirm.buttonCancel')(),
      onClick: onCancel,
    }}
    gap={0}
    destroyOnClose
    centered
  />
);

export default DraftModal;
