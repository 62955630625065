import { Flex, Tooltip, type TooltipProps } from 'antd';
import { PropsWithChildren } from 'react';

import { Button } from '@ui-kit/Button/Button';

import * as S from './styled';

type FormDraftFinalizeActionsFirstTooltipProps = {
  onClick: VoidFunction;
} & TooltipProps;

const FormDraftFinalizeActionsFirstTooltip = ({
  onClick,
  children,
  ...props
}: PropsWithChildren<FormDraftFinalizeActionsFirstTooltipProps>) => (
  <Tooltip
    title={
      <Flex gap={16} vertical>
        <Flex gap={8} vertical>
          <S.Title>{t('invoiceGenerator.draft.firstHelp.title')()}</S.Title>
          <span>{t('invoiceGenerator.draft.firstHelp.description')()}</span>
        </Flex>

        <Button type="primary" size="xs" onClick={onClick}>
          {t('invoiceGenerator.draft.firstHelp.buttonGotIt')()}
        </Button>
      </Flex>
    }
    placement="bottom"
    {...props}
  >
    {children}
  </Tooltip>
);

export default FormDraftFinalizeActionsFirstTooltip;
