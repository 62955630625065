import { type ModalProps } from 'antd';

import { Modal } from '@ui-kit/Modal/Modal';

type FinalizeModalProps = {
  isLoading: boolean;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
} & ModalProps;

const FinalizeModal = ({
  open,
  isLoading,
  onConfirm,
  onCancel,
}: FinalizeModalProps) => (
  <Modal
    isOpen={!!open}
    header={t('invoiceGenerator.finalizeConfirm.title')()}
    description={t('invoiceGenerator.finalizeConfirm.description')()}
    size="sm"
    okButton={{
      loading: isLoading,
      children: t('invoiceGenerator.finalizeConfirm.buttonConfirm')(),
      onClick: onConfirm,
    }}
    cancelButton={{
      children: t('invoiceGenerator.buttonCancel')(),
      onClick: onCancel,
    }}
    gap={0}
    destroyOnClose
    centered
  />
);

export default FinalizeModal;
