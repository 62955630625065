import { type ModalProps } from 'antd';
import { useEffect, useState } from 'react';

import { Modal } from '@ui-kit/Modal/Modal';
import { RadioGroup } from '@components';
import { vatTypes } from '@constants';

import { getTranslatesVatType } from './helpers';
import * as S from './styled';

type SelectVatTypeProps = {
  value?: string;
  onCancel: VoidFunction;
  onChange: (type: string) => void;
} & ModalProps;

const SelectVatType = ({ value, ...props }: SelectVatTypeProps) => {
  const [selectedType, setSelectedType] = useState(value || vatTypes[0]);

  useEffect(() => {
    if (value) {
      setSelectedType(value);
    }
  }, [value]);

  const handleCancel = () => {
    setSelectedType(value || vatTypes[0]);
    props.onCancel();
  };

  const handleSubmit = () => {
    props.onChange(selectedType);
    props.onCancel();
  };

  return (
    <Modal
      isOpen={!!props.open}
      header={t('common.vatType.title')()}
      description={
        <S.Description>{t('common.vatType.description')()}</S.Description>
      }
      size="sm"
      okButton={{
        children: t('common.vatType.buttonSelect')(),
        onClick: handleSubmit,
      }}
      cancelButton={{
        children: t('common.vatType.buttonCancel')(),
        onClick: handleCancel,
      }}
      onCancel={handleCancel}
      destroyOnClose
      centered
    >
      <RadioGroup
        defaultValue={selectedType}
        controls={vatTypes.map((type) => ({
          title: getTranslatesVatType(type).title,
          content: getTranslatesVatType(type).description,
          value: type,
        }))}
        size="large"
        onChange={setSelectedType}
        vertical
      />
    </Modal>
  );
};

export default SelectVatType;
