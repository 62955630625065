import { Dropdown } from 'antd';
import { memo, MouseEvent, useCallback, useId } from 'react';

import { Button } from '@ui-kit/Button/Button';
import { IconMoreVertical } from '@assets';
import { ContactsPopupList } from '@entities';
import { Schemas } from '@api-client/generated/types';
import { useModalManager } from '@context/ModalManager/useModalManager';

import { useGetRules } from '../../rules/useGetRules';
import * as S from '../TransactionsTableFilterForm/styled';

const stopPropagation = (event: React.MouseEvent) => event.stopPropagation();

const NoPropagation = ({ children }: { children: React.ReactNode }) => (
  <div onMouseDown={stopPropagation}>{children}</div>
);

type ContactCellProps = {
  contact: Schemas.Contact | null;
  rawContactName: string | null;
  assignContact: (contact: Schemas.Contact) => void;
  onRulesSubmit: () => void;
};

export const ContactCell = memo((props: ContactCellProps) => {
  const dropdown = useId();
  const contactSelector = useId();

  const modalManager = useModalManager();

  const isDropdownVisible = modalManager.isOpen(dropdown);
  const isContactSelectorVisible = modalManager.isOpen(contactSelector);

  const { setStep, setTransactionsState, setConfig } = useGetRules();

  const openRulesModal = useCallback(() => {
    setConfig({
      onSuccess: props.onRulesSubmit,
      tabs: ['transaction'],
    });

    setTransactionsState({
      conditions: [
        {
          attribute: 'contact_name',
          operator: 'contains',
          value: props.contact?.name || props.rawContactName || '',
        },
      ],
      actions: [{ name: 'assign_contact' }],
    });

    setStep('form');
  }, [
    props.contact?.name,
    props.rawContactName,
    props.onRulesSubmit,
    setConfig,
    setStep,
    setTransactionsState,
  ]);

  const items = {
    items: [
      {
        key: 'assignContact',
        label: t(
          props.contact
            ? 'transactionsPage.contactActions.replaceContact'
            : 'transactionsPage.contactActions.assignContact',
        )(),
        onClick: () => modalManager.replace(contactSelector),
      },
      {
        key: 'createRule',
        label: t('transactionsPage.contactActions.createRule')(),
        onClick: () => {
          modalManager.pop(dropdown);
          openRulesModal();
        },
      },
    ],
  } satisfies Parameters<typeof Dropdown>[0]['menu'];

  const handleAssignContact: typeof props.assignContact = useCallback(
    (contact) => {
      props.assignContact(contact);
      modalManager.pop(contactSelector);
    },
    [props, contactSelector, modalManager],
  );

  const stopEventPropagation = (event: MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
  };

  return (
    <S.Wrapper
      isDropdownOpen={isDropdownVisible}
      isSelectorOpen={isContactSelectorVisible}
      hasInfo={!!props.contact}
      onClick={stopEventPropagation}
    >
      <span>
        {props.contact?.name ||
          props.rawContactName ||
          t('transactionsPage.noContact')()}
      </span>
      <Dropdown
        dropdownRender={(menu) => <NoPropagation>{menu}</NoPropagation>}
        menu={items}
        open={isDropdownVisible}
        onOpenChange={modalManager.createSetModalVisibility(dropdown)}
        trigger={['click']}
      >
        <S.HoverableButton onMouseDown={stopPropagation}>
          <Button type="text" size="xs" icon={<IconMoreVertical />} />
        </S.HoverableButton>
      </Dropdown>
      <ContactsPopupList
        onSelect={handleAssignContact}
        open={isContactSelectorVisible}
        onOpenChange={modalManager.createSetModalVisibility(contactSelector)}
        placement="bottomLeft"
      >
        <S.Invisible />
      </ContactsPopupList>
    </S.Wrapper>
  );
});
