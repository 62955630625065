import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, Flex, Form, Select, Typography } from 'antd';
import { rgba } from 'emotion-rgba';

import { Loader as CustomLoader } from '@components';

export const TableContainer = styled.div`
  .ui-table-row {
    cursor: pointer;
  }
`;

export const Loader = styled(CustomLoader)`
  margin-top: 150px;
`;

export const ExcludedTransaction = styled.span`
  color: ${({ theme }) => theme.colors.text200};
`;

export const FormControl = styled(Form.Item)`
  && {
    margin-bottom: 0;
  }
`;

export const DrawerContainer = styled.div`
  height: calc(100vh - 112px);
`;

export const DrawerTitle = styled(Typography.Title)`
  &.ui-typography {
    font-size: 28px;
    margin-bottom: 0;
  }
`;

export const SwitchLabel = styled.div`
  font-weight: 600;
`;

export const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.bgDarkGrey};
  margin: 0 8px;
`;

export const HoverableButton = styled('div')();

export const Invisible = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
`;

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) =>
    !['isDropdownOpen', 'isSelectorOpen', 'hasInfo'].includes(prop),
})<{
  isDropdownOpen: boolean;
  isSelectorOpen: boolean;
  hasInfo: boolean;
}>`
  justify-content: space-between;
  align-items: center;
  height: inherit;
  padding: 16px;
  margin: 0 -16px;
  position: relative;
  display: flex;
  width: calc(100% + 32px);
  overflow: hidden;

  & > span {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  background-color: ${({ isDropdownOpen, theme }) =>
    isDropdownOpen && theme.colors.grey100};
  color: ${({ hasInfo, theme }) => !hasInfo && theme.colors.grey300};

  ${({ isSelectorOpen, theme }) =>
    isSelectorOpen &&
    css`
      border-radius: 6px;
      outline: 2px solid ${theme.colors.grey300};
      outline-offset: -2px;
      background: ${theme.colors.grey100};
      box-shadow: 0px 3px 8px 0px ${rgba(theme.colors.dark, 0.1)};
    `}

  ${HoverableButton} {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 8px;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.grey100};
    visibility: ${({ isDropdownOpen }) =>
      isDropdownOpen ? 'visible' : 'hidden'};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};

    ${HoverableButton} {
      background-color: ${({ theme }) => theme.colors.grey100};
      visibility: ${({ isSelectorOpen }) =>
        isSelectorOpen ? 'hidden' : 'visible'};
    }
  }
`;

export const MissedFileIconWrapper = styled.span`
  && {
    color: ${({ theme }) => theme.colors.error};
  }
`;

export const AccountSelect = styled(Select)`
  min-width: 150px;
`;

export const TransactionsTotalAmount = styled(Typography.Text)`
  font-weight: bold;
  margin-left: 6px;
`;

export const CashFlowFilterButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>`
  border-radius: 30px;

  && {
    background-color: ${({ isActive, theme }) =>
      isActive && theme.colors.grey300} !important;
  }

  &&:hover:not(:disabled),
  &&:active:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.grey200} !important;
    color: ${({ theme }) => theme.colors.grey500} !important;
  }
`;

export const FilelessFilterButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>`
  border-radius: 30px;

  && {
    background-color: ${({ isActive, theme }) =>
      isActive && theme.colors.grey300} !important;
  }

  &&:disabled {
    color: ${({ theme }) => theme.colors.grey500} !important;
  }

  &&:hover:not(:disabled),
  &&:active:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.grey200} !important;
    color: ${({ theme }) => theme.colors.grey500} !important;
  }
`;

export const TotalBalance = styled(Flex)`
  border-radius: 30px;
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  height: 40px;
  padding: 0 12px;
`;
