export const getTranslatesVatType = (label: string) =>
  label === 'exempt'
    ? {
        title: t('common.vatType.exempt.title')(),
        description: t('common.vatType.exempt.description')(),
      }
    : {
        title: t('common.vatType.eligible.title')(),
        description: t('common.vatType.eligible.description')(),
      };
