import dinero from 'dinero.js';

// Dinero uses ISO 4217 currency codes, but we use a different set
const diffCurrencies = [
  'BYR',
  'CNH',
  'EEK',
  'GGP',
  'IMP',
  'JEP',
  'MRO',
  'MTL',
  'ZMK',
] as const;

// Dinero always expects amount in cents (int), so we need to do some transformations
window.toDinero = (amount, currency, precision) => {
  if (
    currency &&
    diffCurrencies.includes(currency as (typeof diffCurrencies)[number])
  ) {
    console.warn(`${currency} is not supported by Dinero.js`);
  }

  return dinero({
    amount: amount ? amount * 100 : 0,
    currency,
    precision: precision ?? 2,
  } as dinero.Options);
};

window.fromDinero = (d) => d.getAmount() / 100;
